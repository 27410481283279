import React, { useRef, useState } from 'react'
import '../style/header.css'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import facebookIcon from '../assets/facebook.svg'
import instaIcon from '../assets/instagram.svg'
import newsletterIcon from '../assets/newsletter.svg'
import { ReactComponent as MenuIcon } from '../assets/menu-li.svg'

function TopNav({tR}) {

  // const topRef = useRef
  const [isDropdownVisible, setIsDropdownVisible] = useState([false, false, false]);
  const [ showMenu, setShowMenu ] = useState(false)

  const location = useLocation()

  const handleMouseEnter = (id) => {
    setIsDropdownVisible(prev => prev.map((p,i) => i === id ? !p : false));
  };

  const handleMouseLeave = () => {
    setIsDropdownVisible([false, false, false]);
  };

  const handleShowMenu = () => {
    setShowMenu(!showMenu)
  }

  const handleMenuClick = (e) => {
    setIsDropdownVisible(prev => prev.map((p, idx) => {  
      const v = idx == e.target.id ? !p : p;
      return v
    }))
  }

  const handleRoute = () => {
    setIsDropdownVisible([false, false, false]);
    setShowMenu(false)
  }

  const toggle_mb_display = (v) => {
    return showMenu ? 'show' : 'toggle-mb-display' 
  }

  const toggle_submenu = (v) => {
    return v ? '{dis}' : ''
  }

  return (
    <>
    { location.pathname === "/" ? 
     <div className="header-container" ref={tR}>
        <div className='mobile-title'>
          <img src="/logo-small.png" className="img-logo"/>
          <MenuIcon className='img-logo' onClick={handleShowMenu} />
        </div>
        <div className='subscribe-wrap'>
          <button className="btn-subscribe">
            <img src={newsletterIcon}/>
              Subscribe
            </button>
        </div>
        <ul className={`main-menu-container main-menu-home ${toggle_mb_display()}`}>
          <span>
              <li
                onMouseEnter={()=>handleMouseEnter(0)}
              >
                <span id={0} onClick={handleMenuClick}>
                  About
                </span>
              </li>
              {
              <div 
                id={0}
                className={`submenu-container submenu-home `}
                style={isDropdownVisible[0] ? {} : {display: 'none'}}
                onMouseLeave={()=>handleMouseLeave(0)}
              >
                <ul>
                  <li>
                    <NavLink to='/church' onClick={handleRoute}>
                      Our Church
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink to='/pastor' onClick={handleRoute}>
                      Our Pastor
                    </NavLink> 
                  </li>
                </ul>
              </div>
              }
          </span>
          <span>
            <li 
              id={1}
              onMouseEnter={()=>handleMouseEnter(1)}
            >
              <span id={1} onClick={handleMenuClick}>
                Ministries
              </span> 
            </li>
            {
            <div 
              id={1}
              className={`submenu-container submenu-home`}
              style={isDropdownVisible[1] ? {} : {display: 'none'}}
              onMouseLeave={handleMouseLeave}
            >
              <ul>
                <li>
                  <NavLink to='/children' onClick={handleRoute}>
                    Children
                  </NavLink> 
                </li>
                <li>
                  <NavLink to='/youth' onClick={handleRoute}>
                    Youth
                  </NavLink> 
                </li>
                <li>
                  <NavLink to='/wow' onClick={handleRoute}>
                    Women of the Word
                  </NavLink> 
                </li>
                <li>
                  <NavLink to='/mop' onClick={handleRoute}>
                    Men of Purpose
                  </NavLink> 
                </li>
                <li>
                  <NavLink to='/frontline' onClick={handleRoute}>
                    Frontline Band
                  </NavLink> 
                </li>
                <li>
                  <NavLink to='/tucson' onClick={handleRoute}>
                    Tucson Church
                  </NavLink> 
                </li>
              </ul>
            </div>}
          </span>
          <li>
            <NavLink to='/events' onClick={handleRoute}>
              Events
            </NavLink>
          </li>
          <li className='nav-logo'>
            <NavLink to='/' onClick={handleRoute}>
              <img src="/logo-small.png" className="img-logo"/>
            </NavLink>
          </li>
          <span>
              <li
                id={2}
                onMouseEnter={()=>handleMouseEnter(2)}
              >
                <span onClick={ handleMenuClick} id='2'>
                  Watch & Listen
                </span> 
              </li>
              {
                <div 
                  id={1}
                  className={`submenu-container submenu-home `}
                  style={isDropdownVisible[2] ? {} : {display: 'none'}}
                  onMouseLeave={()=>handleMouseLeave(1)}
                >
                  <ul>
                    <li>
                      <NavLink to='/sermon' onClick={handleRoute}>
                        Sermon
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to='/message' onClick={handleRoute}>
                        Message
                      </NavLink>
                    </li>
                  </ul>
                </div>
              }
          </span>
          <li>
            <NavLink to='/contact' onClick={handleRoute}>
              Contact Us
            </NavLink>
          </li>
          <li>
            <NavLink to='/give' onClick={handleRoute}>
              Give
            </NavLink>
          </li>
        </ul>
        <div className="socials-container">
          <a href="https://www.facebook.com/FilAmBaptistChurch" target="_blank">
            <img src={facebookIcon} className="icon-social" alt="facebook icon"/>
          </a>
          <a href="https://www.instagram.com/filamintlbaptist/" target="_blank">
            <img src={instaIcon} className="icon-social" alt="instagram icon"/>
          </a>
        </div>
     </div>
    :
      <div className="header-container header-container-non-home" ref={tR}>
        <div className='non-home-img-wrap'>
          <NavLink to='/' onClick={handleRoute}>
            <img src="/logo-small.png" className="img-logo"/>
          </NavLink>
          <MenuIcon className='img-logo' onClick={handleShowMenu} />
        </div>
        <div>
          <ul className={`main-menu-container main-menu-non-home ${toggle_mb_display()}`}>
            <li > 
              <NavLink to='/' onClick={handleRoute}>
                Home
              </NavLink> 
            </li>
            <span>
              <li 
                id={0}
                onMouseEnter={()=>handleMouseEnter(0)}
              >
                <NavLink>
                  About
                </NavLink> 
              </li>
              {
              <div 
                id={0}
                className={`submenu-container submenu-non-home`}
                style={isDropdownVisible[0] ? {} : {display: 'none'}}
                onMouseLeave={handleMouseLeave}
              >
                <ul>
                  <li>
                    <NavLink to='/church' onClick={handleRoute}>
                      Our Church
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink to='/pastor' onClick={handleRoute}>
                      Our Pastor
                    </NavLink> 
                  </li>
                </ul>
              </div>}
            </span>
            <span>
              <li 
                id={1}
                onMouseEnter={()=>handleMouseEnter(1)}
              >
                <NavLink >
                  Ministries
                </NavLink> 
              </li>
              {
              <div 
                id={1}
                className={`submenu-container submenu-non-home`}
                style={isDropdownVisible[1] ? {} : {display: 'none'}}
                onMouseLeave={handleMouseLeave}
              >
                <ul>
                  <li>
                    <NavLink to='/children' onClick={handleRoute}>
                      Children
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink to='/youth' onClick={handleRoute}>
                      Youth
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink to='/wow' onClick={handleRoute}>
                      Women of the Word
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink to='/mop' onClick={handleRoute}>
                      Men of Purpose
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink to='/frontline' onClick={handleRoute}>
                      Frontline Band
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink to='/tucson' onClick={handleRoute}>
                      Tucson Church
                    </NavLink> 
                  </li>
                </ul>
              </div>}
            </span>
            <span>
              <li
                id={2}
                onMouseEnter={()=>handleMouseEnter(2)}
              >
                <NavLink>
                  Watch & Listen
                </NavLink> 
              </li>
                {
                <div 
                  id={2}
                  className={`submenu-container submenu-non-home `}
                  style={isDropdownVisible[2] ? {} : {display: 'none'}}
                  onMouseLeave={handleMouseLeave}
                >
                  <ul>
                    <li>
                      <NavLink to='/sermon' onClick={handleRoute}>
                        Sermon
                      </NavLink>
                    </li>
                    <li>
                      Message
                    </li>
                  </ul>
                </div>}
            </span>
            <li>
              <NavLink to='/events'  onClick={handleRoute}>
                Events
              </NavLink>
            </li>
            <li>
              <NavLink to='/contact' onClick={handleRoute}>
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink to='/give' onClick={handleRoute}>
                Give
              </NavLink>
            </li>
            <li>
              <div className="socials-container socials-container-non-home">
                <a href="https://www.facebook.com/FilAmBaptistChurch" target="_blank">
                  <img src={facebookIcon} className="icon-social" alt="facebook icon"/>
                </a>
                <a href="https://www.instagram.com/filamintlbaptist/" target="_blank">
                  <img src={instaIcon} className="icon-social" alt="instagram icon"/>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    }
    </>

  )
}

export default TopNav
